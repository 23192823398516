import { getAction,postAction } from '../utils/request'

export function findStudySignAll(data) {
  return getAction('/web/studySign/findStudySignAll',data)
}

export function addStudySign(data) {
  return postAction('/web/studySign/addStudySign',data)
}

export function updateStudySign(data) {
  return postAction('/web/studySign/updateStudySign',data)
}

export function deleteStudySign(data) {
  return getAction('/web/studySign/deleteStudySign',data)
}
